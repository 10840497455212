import React from 'react';
import './BetaLock.scss';

import TWDHoriz from './../../media/twd_logo_horiz.png';
import TWDLogo from './../../media/TheoWyesDavid_Logo_thick.png';

const BETA_PASSWORD = "Launch2020";
const ENABLE_HOSTCHECK = true;
const HOSTCHECK = ENABLE_HOSTCHECK;

interface BetaLockProps {
}

interface State {
  password: string;
  success?: boolean;
  error?: boolean;
  passwordVisible: boolean;
}

class BetaLock extends React.Component<BetaLockProps, State> {
    constructor(props: BetaLockProps) {
        super(props);

        this.state = {
          password: '',
          passwordVisible: false
        };
    }

    componentDidMount() {
      document.getElementById("cprtYear")!.innerHTML = ""+(new Date()).getFullYear();
      
      if(HOSTCHECK) {
      }
    }

    passwordOnChange = (e: React.FormEvent<HTMLInputElement>) => {
      e.persist();
      const input = e.currentTarget;
      this.setState({password: input.value, error: false});
    }

    togglePasswordVisibility = () => this.setState(prev => ({passwordVisible: !prev.passwordVisible}));

    formOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // this.passwordSubmit();
    }
    
    passwordSubmit = () => {
      if (this.state.password === BETA_PASSWORD) {
        // this.setState({success: true, error: false});
        // this.props.signIn();
      } else {
        // this.setState({success: false, error: true});
      }
    }

    modal = () => {
      return <div className="modal">
      <div className='logo'>
        <img
          src={TWDHoriz}
          alt='avatar'
          // className='img-responsive'
          // className='rounded-circle img-responsive'
        />
      </div>
      <div className=''>
        <h2 className=''>Client Portal:</h2>
        <p className="motd-alert">*UNAUTHORIZED ACCESS IS PROHIBITED*</p>
        <form className={`beta-password-form`} onSubmit={this.formOnSubmit}>
          <div>
            <input 
              type="password"
              //value={this.state.password}
              //onChange={this.passwordOnChange}
            />
          </div>
        </form>
      </div>
      <div className='justify-content-center'>
        <button color='green' onClick={this.passwordSubmit}>
          Unlock
        </button>
      </div>
    </div>
  
    }

    lockUI = () => {
        return (
          <div id='betalock'>
            {this.modal()}
          </div>
        );
    }

    footer = () => {
      return <div id="footer" className="siteText">
        <div className="footer-logo">
          {// eslint-disable-next-line jsx-a11y/alt-text
          }<img src={TWDLogo} />
        </div>
        <div className="footer-contents">
          <div>All Rights Reserved.</div>
          <div>Copyright ©<span id="cprtYear">2016 - PRESENT</span></div>
          {// eslint-disable-next-line react/jsx-no-target-blank
          }<div><a className="siteText" href="https://www.theowyesdavid.com" target="_blank">Theo. Wyes David, Ltd.</a></div>
        </div>
        <div className="footer-contents site-links">
          <div>
            {// eslint-disable-next-line react/jsx-no-target-blank
            }<a className="siteText" href="https://research.theowyesdavid.com" target="_blank">
              TWD Research
            </a>
          </div>
          <div>|</div>
          <div>
            {// eslint-disable-next-line react/jsx-no-target-blank
            }<a className="siteText" href="https://github.com/Theo-Wyes-David-Ltd" target="_blank" rel="noopener">
              TWD Open Source
            </a>
          </div>
        </div>
      </div>
    }

    render() {
      return <div className="lockBody">
        {this.lockUI()}
        {this.footer()}
      </div>
    }

}

export default BetaLock;
