import React from 'react';
import './App.css';
import BetaLock from './components/BetaLock';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultCRA = (
  <div className="App">
    <header className="App-header">
      <p>
        Edit <code>src/App.tsx</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </header>
  </div>
);

const App = () => {
  return <BetaLock />;
}

export default App;
